import NPLButton, { BTN_HIERARCHY } from '@/components/npl/NPLButton';
import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import NextImage from '@/components/common/NextImage';
import CommunityDropdown from '@/pages/portal/components/CommunityDropdown';
import HeadlessDropdown from '@/components/common/Form/HeadlessDropdown/HeadlessDropdown';
import { t } from '@/utility/localization';
import {
  CM_PORTAL_HOMEPAGE_ROUTE,
  START_COMMUNITY_ONBOARDING_ROUTE,
  goToCmpHomeWithNewActiveCommunityId
} from '@/utility/routesHelper';
import UserMenuSection from '@/components/sections/UserMenuSection/UserMenuSection';
import Icon from '@/components/npl/Icon';
import { openInNewTab } from '@/utility/helpers';
import { trackGAEvent } from '@/utility/analytics';
import { MEMBER_NAVBAR_DASHBOARD_CLICK } from '@/utility/analyticsConsts';
import { setCookie } from 'cookies-next';
import { COMMUNITY_ID_KEY } from '@/utility/constants';
import NPLBadge from '@/components/npl/NPLBadge';

function AuthNavBar({
  communityInfo,
  isCommunityMember,
  isCommunityAdmin,
  userCommunities,
  isPendingApproval,
  isPortalView = false,
  openSignUpModal
}) {
  const router = useRouter();
  const activeCommunityId = communityInfo?._id;

  const routeToDashboard = () => {
    trackGAEvent(MEMBER_NAVBAR_DASHBOARD_CLICK, {
      communityId: activeCommunityId,
      communityCode: communityInfo?.code
    });
    router.push(
      `${CM_PORTAL_HOMEPAGE_ROUTE}?activeCommunityId=${activeCommunityId}`
    );
  };

  const selectedCommunity = useMemo(() => {
    return userCommunities?.find(
      (community) => community?._id === activeCommunityId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityInfo]);

  const renderCommunitySelector = useCallback(() => {
    const renderCTAButton = (closeDropdown) => {
      if (isCommunityAdmin && !isPortalView) {
        return (
          <NPLButton
            hierarchy={BTN_HIERARCHY.NEUTRAL_PRIMARY}
            buttonText={t('dashboard')}
            size="md"
            onClick={() => {
              closeDropdown();
              routeToDashboard();
            }}
          />
        );
      }
      if (!isCommunityMember && !isPortalView) {
        return (
          <NPLButton
            buttonText={
              isPendingApproval
                ? t('pending-approval')
                : t('join-community')
            }
            disabled={isPendingApproval}
            hierarchy={BTN_HIERARCHY.ACCENT_PRIMARY}
            onClick={() => {
              openSignUpModal();
              closeDropdown();
            }}
          />
        );
      }
      if (isPortalView) {
        return (
          <NPLButton
            hierarchy={BTN_HIERARCHY.OUTLINE}
            buttonText={t('view-as-member')}
            size="md"
            tailIcon="link-external-01"
            onClick={() => {
              openInNewTab(communityInfo?.link);
              closeDropdown();
            }}
          />
        );
      }
      // if isCommunityMember
      return <></>;
    };

    const communityProfileImage =
      communityInfo?.profileImage ??
      communityInfo?.thumbnailImgData?.mobileImgData?.src;

    const handleChangeCommunity = (selectedCommunity) => {
      setCookie(COMMUNITY_ID_KEY, selectedCommunity._id);

      // Not in portal view, is manager, route to CM view of that community
      if (selectedCommunity?.isManager) {
        goToCmpHomeWithNewActiveCommunityId(selectedCommunity._id);
        return;
      }
      // isMember
      router.push(selectedCommunity?.link);
    };

    const handleStartNewCommunity = () => {
      router.push(START_COMMUNITY_ONBOARDING_ROUTE);
    };

    const renderUserRole = () => {
      if (isCommunityAdmin || isPortalView) {
        return t('manager');
      }
      if (isCommunityMember) {
        return t('member');
      }
    };

    return (
      <HeadlessDropdown
        renderField={() => {
          return (
            <div className="c-PublicPageCommunitySelector flex cursor-pointer items-center rounded-12 px-4 py-2 mix-blend-multiply transition-all hover:bg-npl-neutral-light-alpha-3">
              <div className="h-32">
                <NextImage
                  alt="community profile"
                  mobileImgProps={{
                    src: communityProfileImage,
                    width: 32,
                    height: 32,
                    objectFit: 'cover'
                  }}
                  className="aspect-square h-32 w-32 rounded-8 object-cover"
                />
              </div>

              <div className="flex w-full flex-row items-center justify-between rounded-28 py-4 ml-8">
                <span className="w-[100px] overflow-hidden truncate font-semibold md:w-full lg:text-label-lg">
                  {communityInfo?.title}
                </span>
                <div className="ml-8">
                  <Icon
                    name="chevron-selector-vertical"
                    width={16}
                    height={16}
                    fill="#1B1B1880"
                  />
                </div>
              </div>
            </div>
          );
        }}
        renderOptions={({ closeDropdown, openDropdown }) => {
          return (
            <div className="shadow-l2 max-h-[600px] w-[340px] overflow-y-auto overflow-x-hidden rounded-8 bg-white-default pb-8">
              <div className="flex flex-col items-center justify-center gap-12 p-16">
                <NextImage
                  alt="community profile"
                  mobileImgProps={{
                    src: communityProfileImage,
                    width: 64,
                    height: 64,
                    objectFit: 'cover'
                  }}
                  className="aspect-square h-64 w-64 rounded-8 object-cover"
                />
                <div className="text-center text-heading-xs font-semibold">
                  {communityInfo?.title}
                  <div className="flex space-x-8 justify-center items-center mt-4 ">
                    <span className="text-center block text-label-sm font-normal text-npl-text-secondary-on-light">
                      {renderUserRole()}
                    </span>
                    {selectedCommunity?.subscriptions?.[0]
                      ?.onGracePeriod && (
                      <NPLBadge
                        leadIcon="alert-circle"
                        text={t('renew')}
                        type="accent"
                        size="sm"
                      />
                    )}
                  </div>
                </div>
                {renderCTAButton(closeDropdown)}
              </div>
              <CommunityDropdown
                communities={userCommunities}
                showCommunityDropdown={true}
                setShowCommunityDropdown={(value) =>
                  value ? openDropdown() : closeDropdown()
                }
                activeCommunityId={communityInfo?._id}
                setActiveCommunity={(selectedCommunity) => {
                  handleChangeCommunity(selectedCommunity);
                }}
              />
              <div className="rounded-8 bg-white-default p-8">
                <div className="border-t-1 border-npl-neutral-light-solid-6 px-16 pb-8"></div>
                <div
                  className="flex items-center gap-12 rounded-8 px-8 py-16 hover:cursor-pointer hover:bg-npl-neutral-light-solid-3"
                  onClick={handleStartNewCommunity}>
                  <Icon name="plus-circle" width={24} height={24} />
                  <div className="text-label-md font-semibold">
                    {t('start-new-community')}
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityInfo?.profileImage, communityInfo?.title, userCommunities]);

  return (
    <div className="flex items-center justify-between">
      <div>{renderCommunitySelector()}</div>
      <div>
        <div className="flex items-center gap-8">
          {isCommunityAdmin && !isPortalView ? (
            <NPLButton
              hierarchy={BTN_HIERARCHY.NEUTRAL_PRIMARY}
              buttonText={t('dashboard')}
              onClick={routeToDashboard}
              size="sm"
            />
          ) : null}
          <UserMenuSection
            isCommunityAdmin={isCommunityAdmin}
            communityInfo={communityInfo}
          />
        </div>
      </div>
    </div>
  );
}

export default AuthNavBar;
